import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/Home";
import RegisterCompany from './pages/RegisterCompany';
import RegisterCompany2 from './pages/RegisterCompany2';
import Register1 from './pages/Register1';
import Register2 from './pages/Register2';
import RegisterChoice from './pages/RegisterChoice';
import SuccessfulRegistration from './pages/SuccessfulRegistration';
import SuccessfulRegistrationCompany from './pages/SuccessfulRegistrationCompany';
import ForgotPassword from './pages/ForgotPassword';

function App() {

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/company-information" element={<RegisterCompany />} />
        <Route exact path="/register-account" element={<RegisterCompany2 />} />
        <Route exact path="/register-choice" element={<RegisterChoice />} />
        <Route exact path="/personal-account" element={<Register1 />} />
        <Route exact path="/plan-choice" element={<Register2 />} />
        <Route exact path='/forgot-password' element={<ForgotPassword />} />
        <Route exact path="/successful-registration" element={<SuccessfulRegistration />} />
        <Route exact path="/successful-company-registration" element={<SuccessfulRegistrationCompany />} />
      </Routes>
    </Router>
  );
}

export default App;
