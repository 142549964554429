import React, { useEffect, useState } from 'react';
import axios from "../const/interceptorApi";
import { useForm, Controller } from 'react-hook-form';
import Select, { components } from "react-select";
import { useNavigate, useLocation } from 'react-router-dom';
import "../assets/css/global.css"
import { Spinner } from 'reactstrap';
import PolicyPopup from '../components/PolicyPopup';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Typography,
  } from '@mui/material';
import image1 from "../assets/image/regis2@1.png";
import image2 from "../assets/image/regis2@2.png";
import image3 from "../assets/image/regis1@3.png";
import line1 from "../assets/image/line1.png";
import line2 from "../assets/image/line2.png";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { BASE_SIMPRO_LUMEN } from "../const/index"

const RegisterCompany2 = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const [companyData, setCompanyData] = useState(null);
    const requiredFields = ['companyName', 'companySector', 'companyAddress', 'phone']
    useEffect(() => {
      if (!state || requiredFields.some(field => !state[field])) {
        navigate('/company-information');
      } else {
          setCompanyData({
              companyName: state.companyName,
              phone: state.phone,
              companySector: state.companySector,
              companyAddress: state.companyAddress
          });
      }
  }, [state]);
  const images = [
      { url: image1, description: "Company Account Information", color: '#67BEB5' },
      { url: line2, description: ".", color:'white' },
      { url: image2, description: "Personal Information", color: '#559FF8' },
      { url: line1, description: ".", color:'white' },
      { url: image3, description: "Registration Successful", color: 'black' },
  ];

  const PlanOptions = [
    { value: 'Free', label: 'Free' },
    { value: 'Basic', label: 'Basic' },
  ];

  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });

  const [showPolicyPopup, setShowPolicyPopup] = useState(false);
  const [username, setUsername] = useState(state ? state.username : '')
  const [selectedPlan, setSelectedPlan] = useState(state ? state.plan : '');
  const [address, setAddress] = useState(state ? state.address : '')
  const [emailUser, setEmailUser] = useState(state ? state.email : '')
  const [refferal, setRefferalCode] = useState(state ? state.refferal : '')
  const [agreeToTerms, setAgreeToTerms] = useState(state ? state.agreeToTerms : false);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
      type: '',
      message: ''
  });
  const togglePolicyPopup = () => {
    setShowPolicyPopup(!showPolicyPopup);
  };

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm();

  const onSubmit = async () => {
    await handleSave();
  };

  const handleSnackbar = (type, message) => {
    setSnackbarData({
        type,
        message,
    });
    setOpenSnackbar(true);
    setInterval(()=>{
      setOpenSnackbar(false);
    },4000)
  };

  const handleSave = async () => {
      if (!username.trim() || !companyData.companySector || !address.trim()) {
        return;
      }
      setLoading(true);
      const formData = {
        company_name: companyData.companyName,
        username,
        cluster: companyData.companySector.value,
        email: emailUser,
        address,
        phone_no: companyData.phone,
        type_account: 'Company',
        type_paket: selectedPlan.value,
        companyAddress : companyData.companyAddress
      }
      if(refferal && refferal !== '') {
        formData.refferal = refferal;
      }
    const USER_REGISTER = `${BASE_SIMPRO_LUMEN}/user-register`;
    const result = await axios.post(USER_REGISTER, formData)
        .then(res => res)
        .catch((error) => error.response);
    if (result && result.data && result.data.code == 200) {
      setLoading(false);
      handleSnackbar('success', result.data.message);
      navigate('/successful-company-registration', {
        state: {
            companyName: companyData.companyName,
            phone: companyData.phone,
            companySector: companyData.companySector,
            companyAddress: companyData.companyAddress,
            username: username,
            email: emailUser,
            password: result.data.generateRandom,
            address
        }
      });
    } else {
      handleSnackbar('error', result.data.message);
      setLoading(false);
    }
  }

  return (
    <>
      <div>
        <div style={{ display:'flex', justifyContent: 'center'  }}>
          <div style={{ width: '100%', marginTop: '20px'}}>
            <div style={{ position: 'relative', marginBottom: '50px' }}>
              <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                {images.map((image, index) => (
                  <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src={image.url} alt={image.description} style={image.url === line1 || image.url === line2 ? { height: '5px' } : { height: '55px', width: '55px' }} />
                    <p style={{ color: image.color, textAlign: 'center', whiteSpace: 'nowrap', fontSize: '12px' }}>{image.description}</p>
                  </div>
                ))}
              </div>
            </div>
            <div style={{marginTop:'50px'}}>
            <Box component="div" sx={{marginTop:'100px', width: '380px', mx: 'auto' }}>
              <div style={{ fontWeight: 'bold', fontSize: '27px', marginBottom: '10px' }}>
                Complete Your Profile!
              </div>
              <div style={{ fontSize: '16px', color: '#777B7E', paddingBottom: '10px' }}>
              For industry regulatory purposes, your details are required. Please ensure your email is correct as the password will be sent via email.
              </div>
              <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
              <FormControl fullWidth>
                  <label htmlFor="username" style={{ color: 'rgb(105, 105, 105)', fontWeight: 'bold', fontSize: '14px' }}>
                    Username<span style={{ color:'red' }}>*</span>
                  </label>
                  <input
                    className="hoverregistrasi"
                    {...register('username', { required: 'Username is required' })}
                    name="username"
                    id="username"
                    type="username"
                    placeholder="Please enter username"
                    style={{
                      border: '1px solid gray',
                      borderRadius: '6px',
                      height: '50px',
                      paddingLeft: '20px',
                      fontSize: '12px',
                    }}
                    value={username}
                    onChange={(e)=> setUsername(e.target.value)}
                  />
                  <div style={{marginBottom:'20px'}}>{errors.username && (
                    <span className="error-message" style={{ color: 'red', fontSize: '12px' }}>
                      {errors.username.message}
                    </span>
                  )}</div>
                </FormControl>
                <FormControl fullWidth>
                    <label htmlFor="Plan" style={{ color: "rgb(105, 105, 105)", fontWeight: 'bold', fontSize: '14px', marginTop: "5px" }}>
                      Choice Plan<span style={{ color: 'red' }}>*</span>
                    </label>
                    <Controller
                      name="selectedPlan"
                      control={control}
                      defaultValue={state ? state.plan : ''}
                      rules={{ required: 'Please select a plan' }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={PlanOptions}
                          onChange={(val) => {
                            field.onChange(val);
                            setSelectedPlan(val);
                          }}
                          placeholder="Select your plan"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              border: '1px solid gray',
                              borderRadius: '6px',
                              height: '60px',
                              paddingLeft: '20px',
                              fontSize: '12px',
                            }),
                          }}
                        />
                      )}
                    />
                    {errors.selectedPlan &&
                      <span className="error-message" style={{ color: 'red', fontSize: '12px' }}>
                        Please select a plan
                      </span>
                    }
                  </FormControl>
                <FormControl fullWidth>
                  <label htmlFor="name" style={{ color: 'rgb(105, 105, 105)', fontWeight: 'bold', fontSize: '14px' }}>
                    Email Address<span style={{ color:'red' }}>*</span>
                  </label>
                  <input
                    className="hoverregistrasi"
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address',
                      },
                    })}
                    name="email"
                    id="email"
                    type="email"
                    placeholder="Enter email address"
                    style={{
                      border: '1px solid gray',
                      borderRadius: '6px',
                      height: '60px',
                      paddingLeft: '20px',
                      fontSize: '12px',
                    }}
                    value={emailUser}
                    onChange={(e) => setEmailUser(e.target.value)}
                  />
                  <div style={{marginBottom:'20px'}}>{errors.email && (
                    <span className="error-message" style={{ color: 'red', fontSize: '12px' }}>
                      {errors.email.message}
                    </span>
                  )} </div>
                </FormControl>
                <FormControl fullWidth>
                  <label htmlFor="address" style={{ color: 'rgb(105, 105, 105)', fontWeight: 'bold', fontSize: '14px' }}>
                    Address<span style={{ color:'red' }}>*</span>
                  </label>
                  <input
                    className="hoverregistrasi"
                    {...register('address', { required: 'Address is required' })}
                    name="address"
                    id="address"
                    type="address"
                    placeholder="Please enter address"
                    style={{
                      border: '1px solid gray',
                      borderRadius: '6px',
                      height: '50px',
                      paddingLeft: '20px',
                      fontSize: '12px',
                    }}
                    value={address}
                    onChange={(e)=>setAddress(e.target.value)}
                  />
                  <div style={{marginBottom:'20px'}}>{errors.address && (
                    <span className="error-message" style={{ color: 'red', fontSize: '12px'}}>
                      {errors.address.message}
                    </span>
                  )}</div>
                </FormControl>
                <FormControl fullWidth>
                  <label htmlFor="refferal" style={{ color: 'rgb(105, 105, 105)', fontWeight: 'bold', fontSize: '14px' }}>
                    Refferal Code
                  </label>
                  <input
                    className="hoverregistrasi"
                    name="refferal"
                    id="refferal"
                    type="refferal"
                    placeholder="Please enter refferal"
                    value={refferal}
                    onChange={(e)=>setRefferalCode(e.target.value)}
                    style={{
                      border: '1px solid gray',
                      borderRadius: '6px',
                      height: '50px',
                      paddingLeft: '20px',
                      fontSize: '12px',
                    }}
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={agreeToTerms}
                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                    color="primary"
                    />
                  }
                  label={
                    <Typography variant="body2">
                      I agree to&nbsp;
                      <small
                          onClick={togglePolicyPopup}
                          style={{ color: '#1976D2', cursor: 'pointer', marginTop:'-20px' }}
                        >
                          terms & conditions
                      </small>
                    </Typography>
                  }
                />

                <Box
                  type="bbox"
                  component="div"
                  display="flex"
                  justifyContent="center"
                  mb={2} 
                >
                  <div className="card-button">
                    <button
                      onClick={() => navigate('/company-information', {
                        state: {
                            username: username,
                            address: address,
                            email: emailUser,
                            refferal: refferal,
                            agreeToTerms: agreeToTerms,
                            plan: selectedPlan ? selectedPlan : state.plan,

                            companyName: companyData.companyName,
                            phone: companyData.phone,
                            companySector: companyData.companySector,                            
                            companyAddress: companyData.companyAddress,
                        }
                    })}
                      type="submit"
                      style={{ color:'#D8100B', border: '2px solid', width: '450px', height: '55px', borderRadius: '6px', textTransform: 'none', fontSize: '16px', marginRight:'5px' }}
                      >
                      Back
                    </button>
                    <button
                      type="login"
                        style={{ width: '450px', height: '55px', borderRadius: '6px', textTransform: 'none', fontSize: '16px', marginLeft:'5px' }}
                        disabled={!agreeToTerms}
                        >
                      {
                        loading ? (
                          <Spinner color="light" size={'sm'}>
                            Loading...
                          </Spinner>
                        ) : (
                          "Save and Continue"
                        )
                      }
                    </button>
                  </div>
                </Box>
              </Box>
            </Box>
            </div>
          </div>
        </div>
        {showPolicyPopup && (
          <PolicyPopup isOpen={showPolicyPopup} togglePolicyPopup={togglePolicyPopup} />
        )}
      </div>
        <Snackbar
          style={{ zIndex: 9999, maxWidth: 400 }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarData.type} sx={{ width: '100%' }}>
              <AlertTitle>{snackbarData.type === 'success' ? 'Success' : 'Error'} Message</AlertTitle>
              {snackbarData.message}
          </Alert>
      </Snackbar>
    </>
  );
  
};

export default RegisterCompany2;
