import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/feature.css';
import krakatau from "../assets/image/2.png" 
import ptsi from "../assets/image/3.png" 
import adw from "../assets/image/6.png" 
import nawakara from "../assets/image/5.png" 

const Partner = () => {
    const titleStyle = { color: '#D8100B' };
    const titleStyleBlue = { color: '#122448' };
  return (
    // <section className="space">
    //   <Container>
    //     <div className="sec-line-wrap">
    //       <div className="sec-line"></div>
    //       <h2 className="sec-title2">Big <span  style={titleStyle}>companies</span> already use <span  style={titleStyleBlue}>OS</span><span  style={titleStyle}>PRO</span></h2>
    //       <div className="sec-line"></div>
    //     </div>
    //     <div className="container vs-carousel text-center" data-slide-show="5" data-md-slide-show="3" data-sm-slide-show="2" data-xs-slide-show="2">
    //       <Col><img src={krakatau} alt="Brand" /></Col>
    //       <Col><img src={ptsi} alt="Brand" /></Col>
    //       <Col><img src={adw} alt="Brand" /></Col>
    //       <Col><img src={nawakara} alt="Brand" /></Col>
    //     </div>
    //   </Container>
    // </section>
    <section className="z-index-common mb-100" data-sec-pos="bottom" data-pos-amount="30px" data-pos-for="#aboutv2" style={{marginTop:'80px', marginBottom:'40px'}}>
    <Container className="wow fadeInUp" data-wow-delay="0.2s">
      <Row className="justify-content-center">
        <Col md="8" lg="8" xxl="3" className="text-center text-xxl-start" style={{width:'100%'}}>
          <div className="title-area" >
            <h2 className="sec-title3 h2" >Big companies already use <span  style={titleStyleBlue}>OS</span><span  style={titleStyle}>PRO</span></h2>
          </div>
        </Col>
        <Col xxl="12">
          <Row className="vs-carousel feature-slide2 text-center text-xxl-start" data-slide-show="3" data-md-slide-show="2">
            <Col xl="3">
              <Col><img src={krakatau} alt="Brand" /></Col>
            </Col>
            <Col xl="3">
              <Col><img src={ptsi} alt="Brand" /></Col>
            </Col>
            <Col xl="3">
              <Col><img src={adw} alt="Brand" /></Col>
            </Col>
            <Col xl="3">
              <Col><img src={nawakara} alt="Brand" /></Col>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </section>
  );
};

export default Partner;
