import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Button,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const PolicyPopup = ({ isOpen, togglePolicyPopup }) => (
  <Dialog open={isOpen} onClose={togglePolicyPopup} fullWidth maxWidth="sm">
    <DialogTitle>
      <Typography variant="h6">Detail Kebijakan</Typography>
      <IconButton
        aria-label="tutup"
        onClick={togglePolicyPopup}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Typography variant="body2">
        Ini adalah detail kebijakan. Tambahkan teks dan informasi Anda di sini.
      </Typography>
    </DialogContent>
    <Button onClick={togglePolicyPopup} sx={{ marginBottom: 2, marginLeft: 2 }}>
      Tutup
    </Button>
  </Dialog>
);

export default PolicyPopup;
