import axios from 'axios'

axios.interceptors.request.use(
  async config => {
    return config;
  },
  error => {
    Promise.reject(error)
  });

axios.interceptors.response.use((response) => {
  return response
}, async function (error) {
  if (error.response.status == 401) {
    localStorage.clear();
    window.location = window.location.origin;
  }
  return Promise.reject(error);
});

export default axios