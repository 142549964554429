import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box } from "@mui/material";
import "../assets/css/global.css"
import { Spinner } from 'reactstrap';
import image1 from "../assets/image/regis2@1.png";
import image2 from "../assets/image/regis3@2.png";
import image3 from "../assets/image/regis4@3.png";
import line2 from "../assets/image/line2.png";
import image4 from "../assets/image/regis4.png";
import { MdOutlineEmail } from "react-icons/md";
import { FaLongArrowAltLeft } from "react-icons/fa";
import axios from "../const/interceptorApi";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { BASE_SIMPRO_LUMEN } from "../const/index"

const SuccessfulRegistration = () => {
    const images = [
        { url: image1, description: "Personal Account Information", color: '#67BEB5' },
        { url: line2, description: ".", color: 'white' },
        { url: image2, description: "Select a Plan", color: '#67BEB5' },
        { url: line2, description: ".", color: 'white' },
        { url: image3, description: "Registration Successful", color: '#67BEB5' },
    ];
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarData, setSnackbarData] = useState({
        type: '',
        message: ''
    });
    const requiredFields = ['fullName', 'phone', 'email', 'role', 'plan', 'address', 'username']

    useEffect(() => {
        if (!state || requiredFields.some(field => !state[field])) {
            navigate(-1);
        }
    }, [state]);

    const handleSnackbar = (type, message) => {
        setSnackbarData({
            type,
            message,
        });
        setOpenSnackbar(true);
        setInterval(() => {
            setOpenSnackbar(false);
        }, 4000)
    };

    const handleSave = async () => {
        setLoading(true)
        const formData = {
            username: state ? state.username : '',
            email: state ? state.email : '',
            password: state ? state.password : ''
        }
        const EMAIL_SENDER = `${BASE_SIMPRO_LUMEN}/send-email`;
        const result = await axios
            .post(EMAIL_SENDER, formData)
            .then(res => res)
            .catch((error) => error.response);
        if (result && result.data && result.data.code == 200) {
            setLoading(false)
            handleSnackbar('success', 'Resend email successful, please check your email!');
        } else {
            setLoading(false)
            handleSnackbar('error', 'Resend email failed');
        }
    }

    return (
        <div>
            <div className="containerStyle">
                <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', marginTop: '20px' }}>

                        <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            {images.map((image, index) => (
                                <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                    <img src={image.url} alt={image.description} style={image.url === line2 ? { height: "5px" } : { height: "55px", width: "55px" }} />
                                    <p style={{ color: image.color, textAlign: 'center', whiteSpace: 'nowrap', fontSize: '12px' }}>{image.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50%', marginTop: '50px' }}>
                        <img src={image4} alt='description' style={{ width: '300px', height: '300px' }} />
                    </div>
                    <Box component="div" sx={{ width: "555px", mx: "auto" }}>
                        <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '26px', marginTop: '20px', marginBottom: '10px', lineHeight: '35px' }}>
                            Registration successful! Please check your email to see the password.
                        </div>
                        <div style={{ fontSize: "16px", color: "rgb(192, 192, 192)", textAlign: 'center', marginBottom: '20px' }}>
                            The first step to an extraordinary experience with us.
                        </div>
                    </Box>
                    <Box
                        type="bbox"
                        component="div"
                        display="flex"
                        justifyContent="center"
                    >
                        <button onClick={() => handleSave()}
                            style={{
                                color: 'white',
                                backgroundColor: '#D8100B',
                                width: '380px',
                                height: '58px',
                                border: '2px solid',
                                borderRadius: '6px',
                                textTransform: 'none',
                                fontSize: '16px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontWeight: '600',
                                marginBottom: '5px',
                            }}>
                            <span>
                                <MdOutlineEmail style={{ height: '25px', width: '25px', marginRight: '10px' }} />
                            </span>
                            {
                                loading ? (
                                    <Spinner color="light" size={'sm'}>
                                        Loading...
                                    </Spinner>
                                ) : (
                                    "Resend Email"
                                )
                            }
                        </button>
                    </Box>
                    <Box
                        type="bbox"
                        component="div"
                        display="flex"
                        justifyContent="center"
                    >
                        <button onClick={() => window.location.href = 'https://project.ospro.id/#/'} style={{
                            color: '#D8100B',
                            width: '380px',
                            height: '58px',
                            borderRadius: '6px',
                            textTransform: 'none',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: '400',
                            marginBottom: '20px', // Add margin to create space between the text and the button
                        }}>
                            <span>
                                <FaLongArrowAltLeft style={{ height: '25px', width: '25px', marginRight: '10px' }} />
                            </span>
                            Login Page
                        </button>
                    </Box>
                    <Snackbar
                        style={{ zIndex: 9999, maxWidth: 400 }}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={openSnackbar}
                        autoHideDuration={4000}
                        onClose={() => setOpenSnackbar(false)}
                    >
                        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarData.type} sx={{ width: '100%' }}>
                            <AlertTitle>{snackbarData.type === 'success' ? 'Success' : 'Error'} Message</AlertTitle>
                            {snackbarData.message}
                        </Alert>
                    </Snackbar>
                </div>
            </div>
        </div>
    );
}

export default SuccessfulRegistration;
