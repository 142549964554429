import "../assets/css/global.css"
import React, { useState, useEffect } from 'react';
import Gambar from "../assets/image/Login.png";
import { ArrowBack } from "@mui/icons-material";
import { Box, FormControl, IconButton, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import axios from "../const/interceptorApi";
import "../assets/css/Login.css"
import Logo from "../assets/image/LogoOspro.png";
import { Spinner } from 'reactstrap';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { BASE_SIMPRO_LUMEN } from "../const/index"
const ForgotPassword = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    type: '',
    message: ''
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  };

  const handleSnackbar = (type, message) => {
    setSnackbarData({
      type,
      message,
    });
    setOpenSnackbar(true);
    setInterval(()=>{
      setOpenSnackbar(false);
    },4000)
  };

  const handleSave = async () => {
    setLoading(true);
    const formData = {
      "email": email
    }
    const EMAIL_SENDER = `${BASE_SIMPRO_LUMEN}/send-email`;
    const result = await axios
      .post(EMAIL_SENDER, formData)
      .then(res => res)
      .catch((error) => error.response);

    if (result && result.data && result.data.code == 200) {
      setLoading(false);
      handleSnackbar('success', 'Forgot password successfully, please check your email!')
    } else {
      setLoading(false);
      handleSnackbar('error', 'Forgot password failed');
    }
  }
  return (
    <div className="containerStyle">
      <div className="leftColumnStyle">
        <img src={Gambar} alt="Deskripsi gambar" className='gambar' />
      </div>
      <div className="rightColumnStyle">
        <IconButton onClick={() => navigate('/login')}>
          <ArrowBack style={{ fontSize: '30px' }} />
          <span style={{ fontSize: '15px' }}>Back</span>
        </IconButton>
        <Box component="div" sx={{ mt: 8, width: "380px", mx: "auto" }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <img src={Logo} style={{ width: '100px', marginBottom: '12px' }}></img>
          </Box>
          <Typography component="h1" variant="h5" fontWeight="bold">
            Forgot Password
          </Typography>
          <Typography
            style={{ fontSize: "13px", color: "rgba(0, 0, 0, 0.7)", marginTop: '5px', marginBottom: '15px' }}
          >
            Please enter your email in field and check!
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <FormControl fullWidth>
              <label htmlFor="name" style={{ color: 'black', fontSize: '15px' }}> Email </label>
              <input name="email" id="email" type="email" placeholder="Enter Your Email" style={{
                border: '1px solid gray', borderRadius: '15px', height: '45px', paddingLeft: '10px', fontSize: '12px', marginBottom: "15px"
              }} onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
            <Box
              type="bbox"
              component="div"
              display="flex"
              justifyContent="center"
            >
              <div className="card-button" style={{ marginBottom: '15px' }}>
                <button disabled={loading && true} onClick={() => handleSave()} type="login" style={{ fontSize: '14px', fontWeight: "bold" }}>
                  {
                    loading ? (
                      <Spinner color="light" size={'sm'}>
                        Loading...
                      </Spinner>
                    ) : (
                      "Forgot Password"
                    )
                  }
                </button>
              </div>
            </Box>
          </Box>
          <Snackbar
            style={{ zIndex: 9999, maxWidth: 400 }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={openSnackbar}
            autoHideDuration={4000}
            onClose={() => setOpenSnackbar(false)}
          >
            <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarData.type} sx={{ width: '100%' }}>
              <AlertTitle>{snackbarData.type === 'success' ? 'Success' : 'Error'} Message</AlertTitle>
              {snackbarData.message}
            </Alert>
          </Snackbar>
        </Box>
      </div>
    </div>
  );
};

export default ForgotPassword;
