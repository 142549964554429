import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Button, TextField, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import './Modal.css';
import Slide from '@mui/material/Slide';
import { BASE_SIMPRO_LUMEN } from "../const/index"
import axios from 'axios';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = ({ isOpen, toggleModal, openSnackbar, setOpenSnackbar, snackbarData, setSnackbarData }) => {
  const [salesContact, setSalesContact] = useState({
    name: '',
    email: '',
    number_phone: '',
    company_name: '',
    role: '',
    status: 'New Contact'
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSalesContact(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSave = async () => {
    const SALES_CONTACT_ADD = `${BASE_SIMPRO_LUMEN}/sales-contact/add`;
    const result = await axios.post(SALES_CONTACT_ADD, salesContact)
      .then(res => res)
      .catch((error) => error.response);
    if (result && result.data && result.data.code == 200) {
      setSnackbarData({
        type: 'success',
        message: 'Your message has been successfully received by our Sales team! Someone from our team will be in touch with you shortly.',
      });
      setOpenSnackbar(true);
    } else {
      setSnackbarData({
        type: 'error',
        message: 'Failed Save Data',
      });
      setOpenSnackbar(true);
    }
  }

  return (
    <Dialog open={isOpen} onClose={toggleModal} TransitionComponent={Transition} maxWidth='md'>
      <DialogTitle>
        <div className="title">
          <div className="text2">
            <div className="contact-sales" style={{ fontSize: '20px', fontFamily: 'poppins', paddingBottom: '5px', fontWeight: '600' }}>Contact Sales</div>
            <div className="please-enter-your" style={{ fontSize: '16px', fontFamily: 'poppins', fontWeight: '400' }}>
              Please enter your personal data to select the Enterprise Plan,
              sales will contact you to continue the Enterprise Plan
            </div>
          </div>
        </div>
        <IconButton
          aria-label="tutup"
          onClick={toggleModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form className="input-form">
          <div className="div1">
            <div className="name">Name</div>
            <input
              className="form-name"
              placeholder="Enter your name"
              type="text"
              name="name"
              value={salesContact.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="div1">
            <div className="name">Email</div>
            <input
              className="form-name"
              placeholder="Enter your email"
              type="text"
              name="email"
              value={salesContact.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="div1">
            <div className="name">Phone number</div>
            <input
              className="form-name"
              placeholder="Enter your phone number"
              type="text"
              name="number_phone"
              value={salesContact.number_phone}
              onChange={handleInputChange}
            />
          </div>
          <div className="div1">
            <div className="name">Company name</div>
            <input
              className="form-name"
              placeholder="Enter your company name"
              type="text"
              name="company_name"
              value={salesContact.company_name}
              onChange={handleInputChange}
            />
          </div>
          <div className="div1">
            <div className="name">Position</div>
            <input
              className="form-name"
              placeholder="Enter your position"
              type="text"
              name="role"
              value={salesContact.role}
              onChange={handleInputChange}
            />
          </div>
        </form>
      </DialogContent>
      <div className="div5">
        <button className="button" onClick={toggleModal}>
          <div className="cancel">Cancel</div>
        </button>
        <button className="button1">
          <div className="contact-to-sales" onClick={() => handleSave()}>Contact to sales</div>
        </button>
      </div>
    </Dialog>
  );
};


export default Modal;
