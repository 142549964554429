import "../assets/css/global.css";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, FormControl } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import image1 from "../assets/image/regis1@1.png";
import image2 from "../assets/image/regis1@2.png";
import image3 from "../assets/image/regis1@3.png";
import line1 from "../assets/image/line1.png";

const RegisterCompany = () => {
  const { control, register, handleSubmit, formState: { errors } } = useForm();
  const images = [
    { url: image1, description: "Business Account Information", color: "#559FF8" },
    { url: line1, description: ".", color: "white" },
    { url: image2, description: "Personal Information", color: "black" },
    { url: line1, description: ".", color: 'white' },
    { url: image3, description: "Registration Successful", color: 'black' },
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [companyData, setCompanyData] = useState(null);
  useEffect(() => {
    if (state) {
      setCompanyData({
        username: state ? state.username : '',
        plan: state ? state.plan : '',
        address: state ? state.address : '',
        email: state ? state.email : '',
        refferal: state ? state.refferal : '',
        agreeToTerms: state ? state.agreeToTerms : ''
      });
    }
  }, [state]);

  const SectorOptions = [
    { value: 'Communications', label: 'Communications' },
    { value: 'Utilities', label: 'Utilities' },
    { value: 'Real_estate', label: 'Real estate' },
    { value: 'Materials', label: 'Materials' },
    { value: 'Insurance', label: 'Insurance' },
    { value: 'Healthcare', label: 'Healthcare' },
    { value: 'Energy', label: 'Energy' },
    { value: 'Consumer_discretionary', label: 'Consumer discretionary' },
    { value: 'Consumer', label: 'Consumer' },
    { value: 'Transportation', label: 'Transportation' },
    { value: 'Industrials', label: 'Industrials' },
    { value: 'Information_Technology', label: 'Information Technology' },
    { value: 'Financials', label: 'Financials' },
    { value: 'Retail', label: 'Retail' },
  ];

  const onSubmit = (data) => {
    navigate('/register-account', {
      state: {
        companyName: data.companyName,
        phone: data.phone,
        companySector: data.companySector,
        companyAddress: data.companyAddress,

        username: companyData ? companyData.username : '',
        plan: companyData ? companyData.plan : '',
        address: companyData ? companyData.address : '',
        email: companyData ? companyData.email : '',
        refferal: companyData ? companyData.refferal : '',
        agreeToTerms: companyData ? companyData.agreeToTerms : '',
      }
    });
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <div style={{ width: '100%', marginTop: '20px' }}>
          <div style={{ position: 'relative', marginBottom: '50px' }}>
            <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              {images.map((image, index) => (
                <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img src={image.url} alt={image.description} style={image.url === line1 || image.url === line1 ? { height: '5px' } : { height: '55px', width: '55px' }} />
                  <p style={{ color: image.color, textAlign: 'center', whiteSpace: 'nowrap', fontSize: '12px' }}>{image.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div style={{ marginTop: '50px' }}>
          <Box component="div" sx={{ marginTop: '10px', width: "380px", mx: "auto" }}>
            <div style={{ fontWeight: "bold", fontSize: "26px", marginBottom: "10px" }}>
              Register Business Account!
            </div>
            <div style={{ fontSize: "16px", color: "#777B7E", marginBottom: "20px" }}>
              For industry regulatory purposes, your details are required. Please ensure your email is correct as the password will be sent via email.
            </div>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
              <FormControl fullWidth>
                <label htmlFor="name" style={{ color: 'rgb(105, 105, 105)', fontWeight: 'bold', fontSize: '14px' }}>
                  Business Name<span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  {...register("companyName", { required: true })}
                  name="companyName"
                  id="companyName"
                  type="text"
                  placeholder="Enter company name"
                  style={{
                    border: '1px solid gray',
                    borderRadius: '6px',
                    height: '50px',
                    paddingLeft: '20px',
                    fontSize: '12px',
                  }}
                  defaultValue={state ? state.companyName : ''}
                  className="hoverregistrasi"
                />
                {errors.companyName &&
                  <span className="error-message" style={{ color: 'red', fontSize: '12px' }}>
                    Business name is required
                  </span>
                }
              </FormControl>
              <FormControl fullWidth>
                <label htmlFor="phone" style={{ color: "rgb(105, 105, 105)", fontWeight: "bold", fontSize: "14px", marginTop: "5px" }}>
                  Business Phone Number<span style={{ color: 'red' }}>*</span>
                </label>
                <div className="hoverregistrasi" style={{ borderRadius: "6px", height: "60px", fontSize: "12px", display: "flex", alignItems: "center" }}>
                <input
                    {...register("phone", { required: true })}
                    type="tel"
                    id="phone"
                    name="phone"
                    defaultValue={state ? state.phone : ''}
                    placeholder="Enter phone number, ex. 08...."
                    maxLength={12}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                    style={{
                        border: '1px solid gray',
                        borderRadius: '6px',
                        height: '50px',
                        paddingLeft: '20px',
                        fontSize: '12px',
                    }}
                />
                </div>
                {errors.phone && <span className="error-message" style={{ color: 'red', fontSize: '12px' }}>Phone Number is Required</span>}
              </FormControl>
              <FormControl fullWidth>
                <label htmlFor="companyAddress" style={{ color: 'rgb(105, 105, 105)', fontWeight: 'bold', fontSize: '14px', marginTop: "5px" }}>
                  Business Address<span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  {...register("companyAddress", { required: true })}
                  name="companyAddress"
                  id="companyAddress"
                  type="text"
                  placeholder="Enter company address"
                  style={{
                    border: '1px solid gray',
                    borderRadius: '6px',
                    height: '50px',
                    paddingLeft: '20px',
                    fontSize: '12px',
                  }}
                  defaultValue={state ? state.companyAddress : ''}
                  className="hoverregistrasi"
                />
                {errors.companyAddress && <span className="error-message" style={{ color: 'red', fontSize: '12px' }}>Company Address is Required</span>}
              </FormControl>
              <FormControl fullWidth>
                <label htmlFor="companySector" style={{ color: 'rgb(105, 105, 105)', fontWeight: 'bold', fontSize: '14px', marginTop: "5px" }}>
                  Sector Business<span style={{ color: 'red' }}>*</span></label>
                <Controller
                  name="companySector"
                  control={control}
                  defaultValue={state ? state.companySector : ''}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={SectorOptions}
                      onChange={(val) => {
                        field.onChange(val);
                      }}
                      placeholder="Select your sector company"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: '1px solid gray',
                          borderRadius: '6px',
                          height: '60px',
                          paddingLeft: '20px',
                          fontSize: '12px',
                        }),
                      }}
                    />
                  )}
                  rules={{ required: true }}
                />
                {errors.companySector &&
                  <span className="error-message" style={{ color: 'red', fontSize: '12px' }}>
                    Please select a sector Business
                  </span>
                }
              </FormControl>
              <Box type="bbox" component="div" display="flex" justifyContent="center" mb={2} mt={2}>
                <div className="card-button">
                  <button
                    onClick={() => navigate('/register-choice',{
                      state: { plan: state ? state.plan : '' }
                    })}
                    type="submit"
                    style={{ color: '#D8100B', border: '2px solid', width: '450px', height: '55px', borderRadius: '6px', textTransform: 'none', fontSize: '16px', marginRight: '5px' }}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    style={{ width: '450px', height: '55px', borderRadius: '6px', textTransform: 'none', fontSize: '16px', backgroundColor: '#D8100B', color: 'white', marginLeft: '5px' }}
                  >
                    Save and Continue
                  </button>
                </div>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default RegisterCompany;