import React from "react";
import { Container, Row, Col } from "reactstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/feature.css';
import { FaBezierCurve } from "react-icons/fa";
import curve from "../assets/image/future01.png" 
import absen from "../assets/image/future02.png" 
import budget from "../assets/image/future03.png" 
import gantt from "../assets/image/future04.png" 

const OurFeature = () => {
  const titleStyle = { color: '#D8100B' };

  return (
    <section className="z-index-common mb-100" data-sec-pos="bottom" data-pos-amount="30px" data-pos-for="#aboutv2">
      <Container className="wow fadeInUp" data-wow-delay="0.2s">
        <Row className="justify-content-center">
          <Col md="8" lg="8" xxl="3" className="text-center text-xxl-start">
            <div className="title-area">
              <h2 className="sec-title3" style={titleStyle}>Our Feature</h2>
              <span className="sec-subtitle"><i className="fas fa-bring-forward"></i>Special feature create just for you, with our feature, you can manage your plan and task  better and faster than using other app.</span>
            </div>
          </Col>
          <Col xxl="12">
            <Row className="vs-carousel feature-slide2 text-center text-xxl-start" data-slide-show="3" data-md-slide-show="2">
              <Col xl="3">
                <div className="feature-style2">
                  <div className="fab feature-icon "><img src={curve} alt="Features" className="fab" /></div>
                  <h3 className="feature-title h5" style={titleStyle}><a className="text-inherit">Curve</a></h3>
                  <p className="feature-text">Unleash your potential with Curve Services. Experience innovation, growth, and success.</p>
                </div>
              </Col>
              <Col xl="3">
                <div className="feature-style2">
                  <div className="feature-icon"><img src={absen} alt="Features" /></div>
                  <h3 className="feature-title h5"style={titleStyle}><a className="text-inherit" >Absence</a></h3>
                  <p className="feature-text">Effortlessly manage absences with Absence Services. Simplify processes, and save time.</p>
                </div>
              </Col>
              <Col xl="3">
                <div className="feature-style2">
                  <div className="feature-icon"><img src={budget} alt="Features" /></div>
                  <h3 className="feature-title h5"style={titleStyle}><a className="text-inherit" >Budget</a></h3>
                  <p className="feature-text">Empower your financial journey with Budget Services. Take control, set goals, and thrive.</p>
                </div>
              </Col>
              <Col xl="3">
                <div className="feature-style2">
                  <div className="feature-icon"><img src={gantt} alt="Features" /></div>
                  <h3 className="feature-title h5"style={titleStyle}><a className="text-inherit" >Gantt</a></h3>
                  <p className="feature-text">Visualize your project's progress with Gantt Chart Services and Simplify planning.</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default OurFeature;
