import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from '../components/Modal';
import PolicyPopup from '../components/PolicyPopup';
import '../assets/css/feature.css';
import { FaCheck } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate, useLocation } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
const PricingSection = () => {
    const titleStyle = { color: '#D8100B' };
    const navigate = useNavigate();
    const [showPolicyPopup, setShowPolicyPopup] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarData, setSnackbarData] = useState({ type: 'success', message: '' });

    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <>
            <Snackbar
                style={{ zIndex: 9999, maxWidth: 400 }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarData.type} sx={{ width: '100%' }}>
                    <AlertTitle>{snackbarData.type == 'success' ? 'Success' : 'Error'} Message</AlertTitle>
                    {snackbarData.message}
                </Alert>
            </Snackbar>

            <section className="space-extra">
                <Container className="wow fadeInUp" data-wow-delay="0.2s">
                    <Row className="justify-content-center text-center">
                        <Col xl="6">
                            <div className="title-area">
                                <h2 className="sec-title3 h1">Powerful features for <span style={titleStyle}>powerful businesses</span></h2>
                                <span className="sec-subtitle">Choose a plan that’s right for you</span>
                            </div>
                        </Col>
                    </Row>
                    <Row className="vs-carousel" data-slide-show="3" data-md-slide-show="2" data-center-mode="true">
                        <Col xl="4">
                            <div className="price-style1">
                                <div className="price-shape"></div>
                                <h3 className="price-package h5">Free Plan</h3>
                                <div className="price-amount h1">-<span className="price-duration">/30 days</span></div>
                                <div className="price-features">
                                    <ul>
                                        <li className='d-flex align-items-center'><i ><FaCheck /></i>1 project</li>
                                        <li className='d-flex align-items-center'><i ><FaCheck /></i>30 days free access</li>
                                        <li className='d-flex align-items-center'><i ><FaCheck /></i>Storage 50MB</li>
                                        <li className='d-flex align-items-center'><i ><FaCheck /></i>Single User</li>
                                        <li className='d-flex align-items-center'><i ><FaCheck /></i>Timeline dynamic gantt</li>
                                        <li className='d-flex align-items-center'><i ><FaCheck /></i>Critical path</li>
                                        <li className='d-flex align-items-center'><i ><FaCheck /></i>Project progress reports on web</li>
                                        <li className='d-flex align-items-center'><i style={{ color: 'red' }}><IoClose /></i>Project progress reports on mobile</li>
                                        <li className='d-flex align-items-center'><i style={{ color: 'red' }}><IoClose /></i>S-curve</li>
                                        <li className='d-flex align-items-center'><i style={{ color: 'red' }}><IoClose /></i>Project dashboard</li>
                                        <li className='d-flex align-items-center'><i style={{ color: 'red' }}><IoClose /></i>Dashboard customable</li>
                                        <li className='d-flex align-items-center'><i style={{ color: 'red' }}><IoClose /></i>50 user collaboration</li>
                                        <li className='d-flex align-items-center'><i style={{ color: 'red' }}><IoClose /></i>24/7 customer support</li>
                                        <li className='d-flex align-items-center'><i style={{ color: 'red' }}><IoClose /></i>Integration with third-party tools</li>
                                        <li className='d-flex align-items-center'><i style={{ color: 'red' }}><IoClose /></i>In-depth project analysis and reporting</li>
                                        <li className='d-flex align-items-center'><i style={{ color: 'red' }}><IoClose /></i>Priority customer support</li>
                                        <li className='d-flex align-items-center'><i style={{ color: 'red' }}><IoClose /></i>Customized training</li>
                                    </ul>
                                </div>
                                <a onClick={() => navigate('/register-choice', {
                                    state: {
                                        plan: {
                                            label: 'Free',
                                            value: 'Free'
                                        }
                                    }
                                })}
                                    style={{ cursor: 'pointer' }}
                                    className="vs-btn d-flex align-items-center mb-20"> <span className='mr-10'>Get Started</span> <FaArrowRight /></a>
                            </div>
                        </Col>
                        <Col xl="4">
                            <div className="price-style1">
                                <div className="price-shape"></div>
                                <h3 className="price-package h5">Basic Plan</h3>
                                <div className="price-amount h1">250K<span className="price-duration">/Month</span></div>
                                <div className="price-features">
                                    <ul>
                                        <li className='d-flex align-items-center'><i ><FaCheck /></i>10 project</li>
                                        <li className='d-flex align-items-center'><i ><FaCheck /></i>Unlimited access</li>
                                        <li className='d-flex align-items-center'><i ><FaCheck /></i>Storage 500MB</li>
                                        <li className='d-flex align-items-center'><i ><FaCheck /></i>10 User</li>
                                        <li className='d-flex align-items-center'><i ><FaCheck /></i>Timeline dynamic gantt</li>
                                        <li className='d-flex align-items-center'><i ><FaCheck /></i>Critical path</li>
                                        <li className='d-flex align-items-center'><i ><FaCheck /></i>Project progress reports on web</li>
                                        <li className='d-flex align-items-center'><i ><FaCheck /></i>Project progress reports on mobile</li>
                                        <li className='d-flex align-items-center'><i ><FaCheck /></i>S-curve</li>
                                        <li className='d-flex align-items-center'><i ><FaCheck /></i>Project dashboard</li>
                                        <li className='d-flex align-items-center'><i ><FaCheck /></i>Dashboard customable</li>
                                        <li className='d-flex align-items-center'><i ><FaCheck /></i>50 user collaboration</li>
                                        <li className='d-flex align-items-center'><i ><FaCheck /></i>24/7 customer support</li>
                                        <li className='d-flex align-items-center'><i style={{ color: 'red' }}><IoClose /></i>Integration with third-party tools</li>
                                        <li className='d-flex align-items-center'><i style={{ color: 'red' }}><IoClose /></i>In-depth project analysis and reporting</li>
                                        <li className='d-flex align-items-center'><i style={{ color: 'red' }}><IoClose /></i>Priority customer support</li>
                                        <li className='d-flex align-items-center'><i style={{ color: 'red' }}><IoClose /></i>Customized training</li>
                                    </ul>
                                </div>
                                <a onClick={() => navigate('/register-choice', {
                                    state: {
                                        plan: {
                                            label: 'Basic',
                                            value: 'Basic'
                                        }
                                    }
                                })}
                                    style={{ cursor: 'pointer' }}
                                    className="vs-btn d-flex align-items-center mb-20"> <span className='mr-10'>Get Started</span> <FaArrowRight /></a>
                            </div>
                        </Col>
                        <Col xl="4">
                            <div className="price-style1">
                                <div className="price-shape"></div>
                                <h3 className="price-package h5">Enterprise Plan</h3>
                                <div className="price-amount h1">Contact Sales<span className="price-duration"></span></div>
                                <div className='mb-20 '>
                                    <div className="price-features">
                                        <ul>
                                            <li className='d-flex align-items-center'><i ><FaCheck /></i>Unlimited project</li>
                                            <li className='d-flex align-items-center'><i ><FaCheck /></i>Unlimited access</li>
                                            <li className='d-flex align-items-center'><i ><FaCheck /></i>Custome Storage</li>
                                            <li className='d-flex align-items-center'><i ><FaCheck /></i>Custome User</li>
                                            <li className='d-flex align-items-center'><i ><FaCheck /></i>Timeline dynamic gantt</li>
                                            <li className='d-flex align-items-center'><i ><FaCheck /></i>Critical path</li>
                                            <li className='d-flex align-items-center'><i ><FaCheck /></i>Project progress reports on web</li>
                                            <li className='d-flex align-items-center'><i ><FaCheck /></i>Project progress reports on mobile</li>
                                            <li className='d-flex align-items-center'><i ><FaCheck /></i>S-curve</li>
                                            <li className='d-flex align-items-center'><i ><FaCheck /></i>Project dashboard</li>
                                            <li className='d-flex align-items-center'><i ><FaCheck /></i>Dashboard customable</li>
                                            <li className='d-flex align-items-center'><i ><FaCheck /></i>50 user collaboration</li>
                                            <li className='d-flex align-items-center'><i ><FaCheck /></i>24/7 customer support</li>
                                            <li className='d-flex align-items-center'><i ><FaCheck /></i>Integration with third-party tools</li>
                                            <li className='d-flex align-items-center'><i ><FaCheck /></i>In-depth project analysis and reporting</li>
                                            <li className='d-flex align-items-center'><i ><FaCheck /></i>Priority customer support</li>
                                            <li className='d-flex align-items-center'><i ><FaCheck /></i>Customized training</li>
                                        </ul>
                                    </div>
                                    <a onClick={toggleModal} style={{ cursor: 'pointer' }} className="vs-btn d-flex align-items-center mb-20"> <span className='mr-10'>Get Started</span> <FaArrowRight /></a>
                                    {showModal && (
                                        <Modal isOpen={showModal} toggleModal={toggleModal} openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} snackbarData={snackbarData} setSnackbarData={setSnackbarData} />
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </section>
        </>
    );
};

const pricingData = [
    {
        name: 'Basic Plan',
        amount: '250K',
        duration: '/Month',
        bgSrc: 'assets/img/shape/price-bg-shape-1-1.png',
        features: ['- 10 projects', '- Unlimited access', '- Timeline dynamic gantt', '- Critical path', '- S-curve', '- Project dashboard', '- Dashboard customable', '- Project progress reports by web', '- Project progress reports by mobile', '- 50 user collaboration', '- 24/7 customer support', '- Store 500MB',]
    },
    {
        name: 'Enterprise',
        amount: 'Contact Sales',
        duration: '',
        bgSrc: 'assets/img/shape/price-bg-shape-1-1.png',
        features: ['- Unlimited projects', '- Unlimited access', '- Advanced project management features', '- In-depth project analysis and reporting', '- Team collaboration and resource management', '- Integration with third-party tools', '- Priority customer support', '- Customized training']
    },

];

export default PricingSection;
