import { useEffect } from 'react';

const ScrollToElement = ({ target }) => {
  useEffect(() => {
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  }, [target]);

  return null;
};

export default ScrollToElement;
