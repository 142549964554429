import React, { useState } from 'react';
import { Link } from "react-router-dom"
import Logo from "../assets/image/LogoOspro.png"
import { Nav } from 'react-bootstrap';
import { FaBars } from "react-icons/fa"
import { ImCross } from "react-icons/im"
import ScrollToElement from './ScrollToElement';
import '../assets/css/navbar.css';


const Navbar = () => {
  const [Mobile, setMobile] = useState(false)
  const [scrollTarget, setScrollTarget] = useState(null);
  const handleScroll = (target) => {
    const targetElement = document.querySelector(target);
    setScrollTarget(targetElement);
  };
  const buttonLogin = {
    backgroundColor: '#3498db',
    color: '#ffffff',
    borderRadius: '5px',
    padding: '10px 20px',
    margin: '0 10px',
  };

  return (
    <>
      <nav className='navbarr'>
        <img src={Logo} alt="Logo" className="logo" />
        {/*
        if large screen ma xa bhane Mobile add huxa
        if mobile screen ma xa bhane nav-links-mobile add huxa
        */}
        <ul className={Mobile ? "nav-links-mobile" : "nav-links"} onClick={() => setMobile(false)}>
          <Link onClick={() => handleScroll("#Home")}>
            <li>Home</li>
          </Link>
          <Link onClick={() => handleScroll("#OurFeature")}>
            <li>Feature</li>
          </Link>
          <Link onClick={() => handleScroll("#CardPlan")}>
            <li>Price</li>
          </Link>
          <Link onClick={() => handleScroll("#Registrasi")}>
            <li>Demo</li>
          </Link>
          <Link onClick={() => handleScroll("#AboutUs")}>
            <li>About Us</li>
          </Link>
          <Link to="https://project.ospro.id/#/" >
            <li onClick={() => setMobile(false)} style={buttonLogin} className='buttonlogin'>Login</li>
          </Link>
        </ul>
        {/* 
        whenever we click on button = setMobile(!Mobile) ==  is mobile oppsite to setMobile 
        */}
        <button className='mobile-menu-icon' style={{height:"24px"  }} onClick={() => setMobile(!Mobile)}>
          {Mobile ? <ImCross /> : <FaBars />}
        </button>
        {scrollTarget && (
          <ScrollToElement target={scrollTarget} />
        )}
      </nav>
    </>
  )
}
export default Navbar