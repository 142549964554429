import React, { useState, useEffect } from 'react';
import Object from "../assets/image/bg (1).png";
import { useNavigate } from 'react-router-dom';
import ScrollToElement from './ScrollToElement'; 


const ProjectControl = () => {
    const [scrollTarget, setScrollTarget] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Reset scrollTarget when the component mounts or navigation occurs
        setScrollTarget(null);
    }, [navigate]);

    const handleScroll = (target) => {
      const targetElement = document.querySelector(target);
      setScrollTarget(targetElement);
    };
    

    return (
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 md:mx-16" id="Home" style={{paddingTop:'150px', gap:'25px', paddingBottom:'125px'}}>
          <div style={{display:'flex', flexDirection: 'column',alignItems: 'flex-start',justifyContent: 'center'}}>
          <h1 className="font-semibold" style={{ fontSize:"58px", marginBottom: "8px", lineHeight:'70px' }}>
                    Project Control At One <span style={{ color: '#D8100B', fontSize:"58px" }}> Finger Touch</span>
                </h1>
               
            <div className="text-base md:text-lg mb-4 text-gray-700">
              Present the reality of the project field and provide a complete and
              comprehensive Management Tool platform.
            </div>
            <div style={{display:'flex', gap: '15px', flexWrap:'wrap', width: '100%',}}>
            <button
              onClick={() => navigate('/register-choice')}
              className=" rounded-md text-white w-full md:w-auto text-base md:text-lg"
              style={{padding:'10px 50px', backgroundColor: '#D8100B', fontWeight:'600'}}>
              Register
            </button>
            <button
              onClick={() => handleScroll("#Registrasi")}
              className=" rounded-md text-white w-full md:w-auto text-base md:text-lg"
              style={{padding:'10px 50px', backgroundColor: '#ffffff', border:'solid #D8100B',}}>
              <div style={{ color:'#D8100B', fontWeight:'600'}}>
              Schedule a Demo
                </div>
            </button>
            </div>
          </div>
          <div className="flex justify-center md:justify-start">
            <img
              className="w-full md:w-auto h-auto max-h-96 md:max-h-full object-cover hidden md:block"
              src={Object}
              alt="object"
            />
          </div>
          {scrollTarget && (
        <ScrollToElement target={scrollTarget} />
        )}
        </div>
      );      
}   
export default ProjectControl;
