import { Container, Row, Col, Form, FormGroup, Input, Button } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import axios from "../const/interceptorApi";
import '../assets/css/feature.css';
import osprobg from '../assets/image/osprobg.png';
import { FaArrowRight } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md"
import { FaMapMarkerAlt } from "react-icons/fa";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { BASE_SIMPRO_LUMEN } from '../const/index';
const Registrasi = () => {
  const imagePath = osprobg;
  const [fullName, setFullName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const [message, setMessage] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    type: '',
    message: ''
  });

  const validateForm = () => {
    if (!fullName || !email || !role || !phone) {
      setSnackbarData({
        type: 'error',
        message: 'Please fill in all the required fields.',
      });
      setOpenSnackbar(true);
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setSnackbarData({
        type: 'error',
        message: 'Please enter valid email addresses.',
      });
      setOpenSnackbar(true);
      return false;
    }

    return true;
  };

  const handleCLearData = () => {
    setFullName('');
    setPhone('');
    setEmail('');
    setRole('');
    setMessage('');
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }

    const formData = {
      name: fullName,
      role,
      number_phone: phone.replace(/-/g, ''),
      email,
      status: 'true',
      message,
    };

    const SCHEDULE_DEMO = `${BASE_SIMPRO_LUMEN}/schedule-demo/add`;

    try {
      const result = await axios.post(SCHEDULE_DEMO, formData);
      if (result && result.data && result.data.code === 200) {
        handleCLearData();
        setSnackbarData({
          type: 'success',
          message: 'Schedule demo berhasil dikirim.',
        });
        setOpenSnackbar(true);
      } else {
        setSnackbarData({
          type: 'error',
          message: 'Gagal mengirim schedule demo.',
        });
        setOpenSnackbar(true);
      }
    } catch (error) {
      setSnackbarData({
        type: 'error',
        message: 'Terjadi kesalahan. Silakan coba lagi.',
      });
      setOpenSnackbar(true);
    }
  };
  const onChangePhone = (e) => {
    const value = e.target.value;
    setPhone(value)
    let inputValue = value.replace(/\D/g, '');
    inputValue = inputValue.substring(0, 12);
    if (inputValue.length <= 4) {
      setPhone(inputValue);
    } else if (inputValue.length <= 8) {
      setPhone(`${inputValue.substring(0, 4)}-${inputValue.substring(4)}`);
    } else {
      setPhone(`${inputValue.substring(0, 4)}-${inputValue.substring(4, 8)}-${inputValue.substring(8)}`);
    }
  }
  return (
    <section style={{ backgroundImage: `url(${imagePath})` }}>
      <Container>
        <Row className="gx-60">
          <Col xl="6" className="align-self-center space wow fadeInUp" data-wow-delay="0.2s">
            <span className="sec-subtitle text-white">Schedule a Demo</span>
            <h2 className="sec-title3 h1 text-white">Start your project with ospro</h2>
            <hr className="hr-style1" />
            <p className="mb-4 mt-1 pb-3 text-white">Request a demo now and discover the incredible benefits it offers. Here are the key advantages you'll gain by requesting a demo.</p>
            <div className="gy-30">
              {contactMediaData.map((item, index) => (
                <Col className='pb-10' md="7" key={index}>
                  <div className="contact-media">
                    <div className="contact-media__icon text-center d-flex align-items-center justify-content-center">{item.icon}</div>
                    <div className="media-body">
                      <span className="contact-media__label">{item.label}</span>
                      <p className="contact-media__info">{item.info}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </div>
          </Col>
          <Col xl="6" className="form-wrap1">
            <Form action="#" className="form-style1">
              <h2 className="form-title h4">Schedule a Demo</h2>
              <FormGroup>
                <Input type="text" placeholder="Enter Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)} />
              </FormGroup>
              <FormGroup>
                <Input type="text" placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </FormGroup>
              <FormGroup>
                <Input type="text" placeholder="Enter Your Position" value={role} onChange={(e) => setRole(e.target.value)} />
              </FormGroup>
              <FormGroup>
                <Input type="text" placeholder="Enter Your Phone Number" value={phone} onChange={onChangePhone} />
              </FormGroup>
              <FormGroup>
                <textarea placeholder="Write a short message..." value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
              </FormGroup>
              <div className="form-btn">
                <Button className="vs-btn style5" onClick={() => handleSave()}>Schedule a Demo <FaArrowRight /></Button>
                <Snackbar
                  style={{ zIndex: 9999, maxWidth: 400 }}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={openSnackbar}
                  autoHideDuration={4000}
                  onClose={() => setOpenSnackbar(false)}
                >
                  <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarData.type} sx={{ width: '100%' }}>
                    <AlertTitle>{snackbarData.type === 'success' ? 'Success' : 'Error'} Message</AlertTitle>
                    {snackbarData.message}
                  </Alert>
                </Snackbar>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const contactMediaData = [
  {
    icon: <FaPhoneAlt />,
    label: '24/7 Call Available',
    info: <a href="tel:+628117502838">Sales 08117502838</a>
  },
  {
    icon: <FaMapMarkerAlt />,
    label: 'VISITE JAKARTA OFFICE',
    info: <a href="https://maps.app.goo.gl/5PtNCFH6YhxBZqPj7">Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12140</a>
  },
  {
    icon: <MdEmail />,
    label: 'Write a Quick mail',
    info: 'info@integrasiautama.com'
  }

];


export default Registrasi;
