// LoginComponent.js
import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, InputGroup, InputGroupText, Row, Col } from 'reactstrap';
import "../assets/css/page-auth.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Spinner } from 'reactstrap';
import axios from 'axios'; // Import axios
import { Snackbar, Alert, AlertTitle } from '@mui/material';
import Logo from "../assets/image/LogoOspro.png";
import Gambar from "../assets/image/Login.png";
import Gambar1 from "../assets/image/Login2.png";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { BASE_SIMPRO_LUMEN } from '../const';
import { data } from 'autoprefixer';
import { useHistory } from 'react-router-dom';


const LoginComponent = () => {
  const [username, setUsername] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarData, setSnackbarData] = useState({ type: 'success', message: '' });
  const [defaultPage, setDefaultPage] = useState('');
  const navigate = useNavigate();

  const imageList = [Gambar, Gambar1];


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === imageList.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleSignIn = async () => {
    setLoading(true)
    if (username === '') {
      setOpenSnackbar(true)
      setSnackbarData({
        type: 'error',
        message: 'Please fill username'
      });
      setLoading(false)
      return true;
    }

    if (password === '') {
      setOpenSnackbar(true)
      setSnackbarData({
        type: 'error',
        message: 'Please fill password'
      });
      setLoading(false)
      return true;
    }

    let formData = {
      username,
      password,
    }

    const USER_LOGIN_V2 = `${BASE_SIMPRO_LUMEN}/login`
    const result = await axios.post(USER_LOGIN_V2, formData)
      .then(response => response)
      .catch(error => {
        setLoading(false)
      });
    if (result && result.data && result.data.code === 200) {
      setLoading(false)
      const { access_token, data_user } = result.data.data
      // getDataRole(access_token, data_user.role_id)
      // getDataMenu(access_token, data_user.role_id, data_user.hierarchy, data_user.id, data_user.company_id, data_user.role.name, data_user.role.all_project, data_user.user_name, data_user.configApp)
    } else {
      setLoading(false)
    }
  };

  // const getDataRole = async (token, role_id) => {
  //   const config = {
  //     headers:
  //     {
  //       Authorization: `Bearer ${token}`,
  //       "Content-type": `application/json`
  //     }
  //   };

  //   const payload = {
  //     "paging": { "start": 0, "length": -1 },
  //     "columns": [
  //       { "name": "id", "logic_operator": "=", "value": `${role_id}`, "operator": "AND" }
  //     ],
  //     "joins": [
  //       {
  //         "name": "m_menu",
  //         "column_join": "default_page",
  //         "column_results": ["id", "name", "url"],
  //       },
  //     ],
  //     "orders": { "columns": ["id"], "ascending": false }
  //   }

  //   const result = await axios
  //     .post(ROLE_SEARCH, payload, config)
  //     .then(res => res)
  //     .catch((error) => error.response);

  //   if (result && result.data && result.data.code == 200) {
  //     let resData = result.data.data
  //     setDefaultPage(resData[0].join_first_url)
  //   }
  // }

  // const getDataMenu = async (token, role_id, hierarchy, user_id, company_id, role_name, all_project, user_name, configApp) => {
  //   const config = {
  //     headers:
  //     {
  //       Authorization: `Bearer ${token}`,
  //       "Content-type": `application/json`
  //     }
  //   };

  //   let url = MENU_MANAGEMENT(role_id)
  //   const result = await axios
  //     .get(url, config)
  //     .then(res => res)
  //     .catch((error) => error.response);

  //   if (result && result.data && result.data.code == 200) {
  //     let resData = result.data.data

  //     setLoading(false)
  //     // custom redirect home after login
  //     if (role_id == 28) {
  //       navigate("/dashboard-customer/58/63");
  //     }
  //     else {
  //       navigate({
  //         pathname: "/dashboard",
  //         state: {
  //           menu_login: JSON.stringify(resData),
  //           hierarchy,
  //           role_id,
  //           user_id,
  //           token,
  //           isLogin: true,
  //           company_id,
  //           role_name,
  //           all_project,
  //           user_name,
  //           configApp
  //         }
  //       });
  //     }
  //     if (defaultPage) {
  //       if (role_id === 44) {
  //         navigate({
  //           pathname: defaultPage,
  //           state: {
  //             menu_login: JSON.stringify(resData),
  //             hierarchy,
  //             role_id,
  //             user_id,
  //             token,
  //             isLogin: true,
  //             company_id,
  //             role_name,
  //             all_project,
  //             user_name,
  //             configApp
  //           }
  //         });
  //       } else {
  //         navigate(defaultPage);
  //       }
  //     } else if (role_id == 28) {
  //       navigate("/dashboard-customer/58/63");
  //     }
  //     else {
  //       navigate({
  //         pathname: "/dashboard",
  //         state: {
  //           menu_login: JSON.stringify(resData),
  //           hierarchy,
  //           role_id,
  //           user_id,
  //           token,
  //           isLogin: true,
  //           company_id,
  //           role_name,
  //           all_project,
  //           user_name
  //         }
  //       });
  //     }
  //   } else {
  //     setSnackbarData({
  //       type: 'error',
  //       message: 'Login Failed'
  //     });
  //     setLoading(false)
  //   }
  // }


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Snackbar
        style={{ zIndex: 9999, maxWidth: 400 }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarData.type} sx={{ width: '100%' }}>
          <AlertTitle>{snackbarData.type == 'success' ? 'Success' : 'Error'} Message</AlertTitle>
          {snackbarData.message}
        </Alert>
      </Snackbar>
      <div className="authentication-wrapper authentication-cover authentication-bg">
        <Row className='"authentication-inner'>
          <Col lg="7" className="d-none d-lg-flex p-0">
            <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
              {imageList.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`auth-login-cover-${index}`}
                  className={`img-fluid auth-illustration ${index === currentImageIndex ? 'visible' : 'hidden'}`}
                />
              ))}
            </div>
          </Col>
          <Col lg="5" className="d-flex align-items-center p-sm-5 p-4">
            <div className="w-px-400 mx-auto">
              <div className="app-brand mb-2">
                <a onClick={() => navigate('/')} className="app-brand-link gap-2" style={{ cursor: 'pointer' }}>
                  <img
                    src={Logo}
                    style={{ width: '100px' }}
                    alt="app-logo"
                    className="app-brand-logo demo"
                  />
                </a>
              </div>

              <h3 className="mb-1" style={{ fontSize: '30px', color: '#252525' }}>Welcome to OSPRO!</h3>
              <p className="mb-4">Please sign-in to your account and start the adventure</p>
              <Form id="formAuthentication" className="mb-3">
                <FormGroup className="mb-3">
                  <Label for="username">Username</Label>
                  <Input
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Enter your username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    autoFocus
                    style={{ borderRadius: '15px' }}
                  />
                </FormGroup>
                <FormGroup className="mb-3 form-password-toggle">
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      <Label for="password">Password</Label>
                    </div>
                    <div>
                      <Label onClick={() => navigate('/forgot-password')} style={{ color: '#D8100B', cursor: 'pointer' }}>Forgot Password?</Label>
                    </div>
                  </div>
                  <div className="input-group input-group-merge">
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      className="form-control"
                      name="password"
                      placeholder="********"
                      aria-describedby="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      style={{ borderTopLeftRadius: '15px', borderBottomLeftRadius: '15px' }}
                    />

                    <InputGroupText
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </InputGroupText>

                  </div>
                </FormGroup>
                <Button style={{ backgroundColor: '#D8100B', border: '1px solid #D8100B', padding: '13px', borderRadius: '15px' }} block
                  onClick={() => handleSignIn()}>
                  {
                    loading ? (
                      <Spinner color="light" size={'sm'}>
                        Loading...
                      </Spinner>
                    ) : (
                      "Log In"
                    )
                  }
                </Button>
              </Form>
              <p className="text-center">
                <span>New on our platform? </span>
                <a onClick={() => navigate('/register-choice')} style={{ color: '#D8100B', cursor: 'pointer' }}>
                  <span>Create an account</span>
                </a>
              </p>
              <p className="text-center" >
                <a onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
                  Return to the <span style={{ color: 'blue' }}>Home Page</span>
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LoginComponent;
