import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/feature.css';
import { Link } from "react-router-dom"
import ScrollToElement from './ScrollToElement'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faBehance, faYoutube, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import logo from "../assets/image/new-logo-white.png"

const Footer = () => {
  const [scrollTarget, setScrollTarget] = useState(null);
  const handleScroll = (target) => {
    const targetElement = document.querySelector(target);
    setScrollTarget(targetElement);
  };
  return (
    <footer className="footer-wrapper footer-layout2" style={{ backgroundImage: 'url(assets/img/bg/footer-bg-2-1.jpg)' }}>
      <div className="widget-area">
        <Container>
          <Row className="justify-content-between">
            <Col lg="6" xl="3" md="6">
                <a href=""><img src={logo} alt="TechBiz" className="logofooter" /></a>
                <h3 className="widget_title">Great Experience for Building Customers & Businesses</h3>
                <div className="footer-social"  style={{marginBottom:'30px'}}>
                  <span className="social-title" style={{marginBottom:'10px'}}>Follow Us On:</span>
                    <div style={{display:'flex'}}>
                      <a href="https://www.facebook.com/integrasia" style={{width:'45px'}}>
                        <FontAwesomeIcon icon={faFacebookF} />
                      </a>
                      <a href="https://www.linkedin.com/company/pt.-integrasia-utama/" style={{width:'45px'}}>
                        <FontAwesomeIcon icon={faLinkedinIn} />
                      </a>
                      <a href="https://www.instagram.com/ptintegrasiautama/"  style={{width:'45px'}}>
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                      <a href="https://www.youtube.com/@integrasiautamaofficial8233"  style={{width:'45px'}}>
                        <FontAwesomeIcon icon={faYoutube} />
                      </a>
                    </div>
                </div>
            </Col>
            <Col lg="6" xl="3" md="6">
              <div className="widget widget_nav_menu footer-widget">
                <h3 className="widget_title">Quick Links</h3>
                <div className="menu-all-pages-container footer-links">
                  <ul className="menu">
                  <Link onClick={() => handleScroll("#Home")}>
                    <li>Home</li>
                  </Link>
                  <Link onClick={() => handleScroll("#OurFeature")}>
                    <li>Feature</li>
                  </Link>
                  <Link onClick={() => handleScroll("#CardPlan")}>
                    <li>Price</li>
                  </Link>
                  <Link onClick={() => handleScroll("#Registrasi")}>
                    <li>Demo</li>
                  </Link>
                  <Link onClick={() => handleScroll("#AboutUs")}>
                    <li>About Us</li>
                  </Link>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg="6" xl="3" md="6">
              <div className="widget footer-widget">
                <h3 className="widget_title">Contact</h3>
                <div className="vs-widget-about" style={{display:"flex", flexDirection:"column"}}>
                  <a href="tel:+622122773747" className="footer-text">+622122773747</a>
                  <a href="tel:+62811750238" className="footer-text">+62811750238</a>
                  <a href="https://mail.google.com/mail/u/0/?view=cm&tf=1&fs=1&to=info@integrasiautama.com" target="blank" className="footer-text">info@integrasiautama.com</a>
                </div>
              </div>
            </Col>
            <Col lg="6" xl="3" md="6">
              <div className="widget footer-widget">
                <h3 className="widget_title">Locations</h3>
                <div className="vs-widget-about">
                  <a className="widget_titlee" href="httas://maps.app.goo.gl/zU3PEBZTzjPDYKEA8">
                  House of Integrasia
                  </a>
                  <p className="footer-text">Radio Dalam Square, Jl. Radio Dalam Raya No.1A, Gandaria Utara, Kec. Kby. Baru, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12140</p>
                  <a className="widget_titlee" href="https://maps.app.goo.gl/W1cjdNiJTabprDku5">
                  INNOCENT
                  </a>
                  <p className="footer-text">Subsidiary of Integrasia Utama, Jl. Tembaga, Kel. Lemah Abang, RT: 04, RW: 01, Kecamatan Indramayu, Kab: Indramayu 45212</p>
                </div>
              </div>
            </Col>
            
          </Row>
        </Container>
      </div>
      <div className="copyright-wrap">
        <Container>
          <p className="copyright-text">  Copyright 2024 <a className="text-white" href="">OSPRO</a>. All rights reserved by <a className="text-white" href="https://integrasiautama.com">PT Integrasia Utama</a>.</p>
        </Container>
      </div>
      {scrollTarget && (
      <ScrollToElement target={scrollTarget} />
      )}
    </footer>
  );
};

export default Footer;
