import React from 'react';
import { createTheme } from "@mui/material";
import Navbar from "../components/Navbar";
import CardPlan from "../components/CardPlan";
import AboutIU from "../components/AboutIU";
import Partner from "../components/Partner";
import AboutUs from "../components/AboutUs";
import Footer from "../components/Footer";
import OurFeature from "../components/OurFeature";
import ProjectControl from "../components/ProjectControl";
import Registrasi from "../components/Registrasi";

function Home() {
    const Theme = createTheme({
        typography: {
            "fontFamily": `"Exo", "Helvetica", "Arial", "sans-serif"`,
            "fontSize": 14,
            "fontWeightLight": 300,
            "fontWeightMedium": 400,
            "fontWeightRegular": 500,
            "fontWeightBold": 700
        }
    });
    return (
        <>
            <Navbar />
            <div className="home bg-white">
                <main>
                    <div className="Home">
                        <ProjectControl />
                    </div>
                    <div id="OurFeature">
                        <OurFeature />
                    </div>
                    <div id="AboutUs">
                        <AboutUs />
                    </div>
                    <div id="CardPlan">
                        <CardPlan />
                    </div>
                    <div id="Registrasi">
                        <Registrasi />
                    </div>
                    <div id="Partner">
                        <Partner />
                    </div>
                    <div id="AboutIU">
                        <AboutIU />
                    </div>
                </main>
                <Footer />
            </div>
        </>
    );
}
export default Home;
